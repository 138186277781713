import styles from './utils/styles';

const colors = {
  // old colors (for reference. Will delete these as we replace old components with new ones)
  accordion: {
    background: '#e8e8e8',
    backgroundHover: '#d1d1d1',
  },
  background: '#fff',
  border: styles.colors.blue[100],
  // brandPrimary: styles.colors.blue[500],
  // brandSecondary: 'white',
  breadcrumbLinkColor: styles.colors.blue[500],
  carouselBulletHover: styles.colors.gray[500],
  contentCard: {
    primary: {
      backgroundColor: styles.colors.blue[500],
      color: '#FFFFFF',
      border: styles.colors.blue[100],
    },
    secondary: {
      backgroundColor: styles.colors.blue[50],
      color: styles.colors.blue[500],
      border: styles.colors.blue[100],
    },
    tertiary: {
      backgroundColor: styles.colors.blue[300],
      color: '#FFFFFF',
      border: styles.colors.blue[100],
    },
    quaternary: {
      backgroundColor: 'transparent',
      color: styles.colors.blue[500],
      border: styles.colors.blue[100],
    },
  },
  defaultFont: styles.colors.gray[500],
  headerBackground: 'white', // Need to add value for color too
  inputBorder: styles.colors.gray[300],
  inputFontColor: styles.colors.gray[500],
  link: styles.colors.neutral[600],
  linkHover: styles.colors.blue[500],
  searchBorder: styles.colors.gray[100],
  searchInput: styles.colors.gray[600],
  tagBackground: styles.colors.blue[100],
  cardBackground: 'white',
  cardBackgroundHover: styles.colors.gray[50],
  cardText: styles.colors.gray[500],
  navSearchAllResultsColor: styles.colors.gray[500],
  navSearchHitsContainerText: styles.colors.gray[800],
  searchFormHitBorderColor: styles.colors.gray[200],
};

export default colors;
