import React from 'react';
import { ThemeProvider } from 'styled-components';

import { CartProvider } from '@rsc/contexts/CartContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';

import useSiteSettings from './src/components/hooks/useSiteSettings';
import theme from './src/components/theme';

function RootWrapper({ element }: { element: React.ReactElement }) {
  return (
    <ThemeProvider theme={theme}>
      <SiteSettingsProvider useSiteSettings={useSiteSettings}>
        <CartProvider>
          <CustomerContextProvider>{element}</CustomerContextProvider>
        </CartProvider>
      </SiteSettingsProvider>
    </ThemeProvider>
  );
}

export default RootWrapper;
