import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const comparisonTable = definePartsStyle(() => {
  const repeaters = {
    border: '1px solid #e2e5e9',
    borderColor: 'white',
    padding: '3',
    textAlign: 'center',
  };

  return {
    table: {
      borderCollapse: 'collapse',
      width: 'full',
      lineHeight: '4',
      marginInline: 'auto',
      maxWidth: '1024px',
      tableLayout: 'auto',
      textTransform: 'none',
      overflowX: 'unset',
      overflowY: 'scroll',
      '@media (min-width: 1024px)': {
        fontSize: 'md',
      },
    },
    caption: {
      color: 'primary.700',
      fontSize: 'lg',
      fontWeight: 'black',
      mb: '3',
      mt: 'unset',
      p: 'unset',
      lineHeight: '4',
      textAlign: 'left',
    },
    thead: {
      fontSize: 'md',
    },
    tbody: {
      'tr:nth-child(4n + 2)': {
        backgroundColor: 'primary.50',
      },
      'tr:nth-child(4n + 2) td': {
        borderColor: 'primary.50',
      },
    },
    tr: {
      ...repeaters,
      '&:nth-child(2n) td': {
        paddingBottom: '3',
      },
      '&:nth-child(2n + 1) td': {
        paddingBottom: '2',
        paddingTop: '2',
      },
    },
    th: {
      ...repeaters,
      paddingInline: '1.5',
      color: 'primary.700',
      display: 'table-cell',
      fontSize: 'md',
      fontWeight: 'bold',
      letterSpacing: 'unset',
      textTransform: 'none',
      img: {
        margin: 0,
        width: '100%',
      },
      '&:first-of-type': {
        display: 'none',
        '@media (min-width: 768px)': {
          display: 'table-cell',
        },
      },
      '@media (min-width: 1024px)': {
        fontSize: 'lg',
        padding: '2.5',
        paddingInline: '3',
      },
    },
    td: {
      ...repeaters,
      paddingInline: '1.5',
      width: '25%',
      a: {
        color: 'blue',
      },
      '@media (min-width: 1024px)': {
        paddingInline: '3',
      },
    },
    img: {
      display: 'block',
      marginBlock: '1',
      marginInline: 'auto',
      maxWidth: '100%',
    },
  };
});

const tableTheme = defineMultiStyleConfig({
  variants: { comparisonTable },
});

export default tableTheme;
