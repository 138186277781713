import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const base = defineStyle({
  border: '1px solid',
  borderColor: 'stroke.base',
  background: 'white',
  borderRadius: 'lg',
  p: 2,
  pl: 4,
  fontSize: '1rem',
  lineHeight: '150%',
  letterSpacing: '-0.02rem',

  _focus: {
    borderColor: 'brand.ink',
  },
});

const textareaTheme = defineStyleConfig({
  variants: { base },
  defaultProps: { variant: 'base' },
});

export default textareaTheme;
