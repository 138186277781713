const styles = {
  colors: {
    gray: {
      50: '#F5F5F5',
      100: '#F6F7F8',
      200: '#D4D4D4',
      300: '#B8B8B8',
      400: '#969696',
      500: '#666666',
      600: '#595959',
      700: '#4F4F4F',
      800: '#404040',
      900: '#262626',
    },
    orange: {
      500: '#F79727',
    },
    white: '#ffffff',
    black: '#000000',
    red: {
      100: '#ffdddd',
      300: '#ff5d5d',
      500: '#d91a00',
    },
    green: {
      500: '#8CC63E',
    },
    blue: {
      50: '#EBF7FF',
      100: '#0160a6',
      200: '#A3D9FF',
      300: '#6BC1FF',
      400: '#0A99FF',
      500: '#0067B1',
      600: '#005FA3',
      700: '#005694',
      800: '#00467d',
      900: '#003C66',
    },
    // color tokens from design system figma: https://www.figma.com/file/5gWfBu8z2i5CR5qRCXlpI0/Design-%2F-PetSafe?type=design&node-id=7-2002&t=fWbDH7s5J2bMkHN7-4
    neutral: {
      0: '#FFFFFF',
      50: '#F6F7F8',
      100: '#E2E5E9',
      200: '#C6CAD2',
      300: '#A9B0BC',
      400: '#8D96A5',
      500: '#707B8F',
      600: '#5A6372',
      700: '#434A56',
      800: '#2D3139',
      900: '#031327',
      1000: '#000000',
    },
    primary: {
      50: '#E0EDFC',
      100: '#C0DCFA',
      200: '#A1CAF7',
      300: '#6096E2',
      400: '#3672D1',
      500: '#275FB2',
      600: '#174B93',
      700: '#083874',
      800: '#05254D',
      900: '#031327',
    },
    secondary: {
      50: '#fffde5',
      100: '#fff9bf',
      200: '#fff493',
      300: '#fff066',
      400: '#fdeb3d',
      500: '#ffea00',
      600: '#fed600',
      700: '#febd00',
      800: '#fea400',
      900: '#fd7700',
    },
    accent: {
      50: '#FFFEF0',
      100: '#FFFBCC',
      200: '#FFF799',
      300: '#FFF266',
      400: '#FFEE33',
      500: '#FFEB00',
      600: '#CCBB00',
      700: '#998C00',
      800: '#665E00',
      900: '#332F00',
    },
    danger: {
      50: '#FDF2F2',
      100: '#F7D4D4',
      200: '#F0A8A8',
      300: '#E87D7D',
      400: '#E05252',
      500: '#D92626',
      600: '#AD1F1F',
      700: '#821717',
      800: '#570F0F',
      900: '#2B0808',
    },
    warning: {
      50: '#FEF7F0',
      100: '#FCE6CF',
      200: '#FACC9E',
      300: '#FACC9E',
      400: '#F5993D',
      500: '#F27F0D',
      600: '#C2660A',
      700: '#914D08',
      800: '#613305',
      900: '#301A03',
    },
    success: {
      50: '#F5FAF6',
      100: '#DEEDDF',
      200: '#BDDBBE',
      300: '#9CC99E',
      400: '#7AB87D',
      500: '#59A65D',
      600: '#47854A',
      700: '#366338',
      800: '#244225',
      900: '#122113',
    },
  },
};

export default styles;
