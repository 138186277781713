import Drawer from './drawerTheme';
import Button from './buttonTheme';
import Container from './containerTheme';
import Badge from './badgeTheme';
import FormLabel from './formLabelTheme';
import Input from './inputTheme';
import Textarea from './textareaTheme';
import Checkbox from './checkboxTheme';
import Alert from './toastTheme';
import Table from './tableTheme';

const components = {
  Button,
  Container,
  Badge,
  FormLabel,
  Input,
  Textarea,
  Drawer,
  Checkbox,
  Alert,
  Table,
};

export default components;
