const bodyDefaults = {
  margin: 0,
  fontWeight: 'light',
  letterSpacing: 'normal',
  lineHeight: '150%',
};

const typography = {
  textStyles: {
    display: {
      fontSize: ['3xl', null, '7xl'],
      margin: 0,
      fontWeight: 'bold',
      letterSpacing: 'tight',
      lineHeight: ['100%', null, '115%'],
    },
    h1: {
      fontSize: ['2xl', null, '4xl'],
      margin: 0,
      fontWeight: 'black',
      letterSpacing: 'tight',
      lineHeight: '115%',
    },
    h2: {
      fontSize: ['xl', null, '2xl'],
      margin: 0,
      fontWeight: 'black',
      letterSpacing: 'tight',
      lineHeight: '115%',
    },
    h3: {
      fontSize: ['lg', null, 'xl'],
      margin: 0,
      fontWeight: 'semibold',
      letterSpacing: 'tight',
      lineHeight: '115%',
    },
    h4_5_6: {
      fontSize: ['md', null, 'lg'],
      margin: 0,
      fontWeight: 'semibold',
      letterSpacing: ['normal', 'tight'],
      lineHeight: '115%',
    },
    h4: {
      fontSize: ['md', null, 'lg'],
      margin: 0,
      fontWeight: 'semibold',
      letterSpacing: ['normal', 'tight'],
      lineHeight: '115%',
    },
    h5: {
      fontSize: ['md', null, 'lg'],
      margin: 0,
      fontWeight: 'semibold',
      letterSpacing: ['normal', 'tight'],
      lineHeight: '115%',
    },
    h6: {
      fontSize: ['md', null, 'lg'],
      margin: 0,
      fontWeight: 'semibold',
      letterSpacing: ['normal', 'tight'],
      lineHeight: '115%',
    },
    bodyLarge: {
      ...bodyDefaults,
      fontSize: 'md',
    },
    body: {
      ...bodyDefaults,
      fontSize: 'sm',
    },
    caption: {
      ...bodyDefaults,
      fontSize: 'xs',
      lineHeight: '120%',
    },
    buttonCTA: {
      ...bodyDefaults,
      fontSize: 'md',
      fontWeight: 'semibold',
    },
  },
  fontSizes: {
    xs: '0.875rem', // 14px
    sm: '1rem', // 16px
    md: '1.25rem', // 20px
    lg: '1.5rem', // 24px
    xl: '2rem', // 32px
    '2xl': '2.5rem', // 40px
    '3xl': '3rem', // 48px
    '4xl': '4rem', // 64px
    '5xl': '4.5rem',
    '6xl': '5rem',
    '7xl': '5.5rem', // 88px
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.02em',
    normal: '0',
    wide: '0.02em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    3: '1em', // 100%
    4: '1.15em', // 115%
    5: '1.2em', // 120%
    6: '1.5em', // 150%
  },
};

export default typography;
