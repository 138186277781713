const {
  getLanguageResolver,
  getFilterByDefaultLanguageFunction,
} = require('../../locales');

const defaultLanguage = 'en-us';

const languageResolver = getLanguageResolver(defaultLanguage);

const filterByDefaultLanguage =
  getFilterByDefaultLanguageFunction(defaultLanguage);

module.exports = { defaultLanguage, languageResolver, filterByDefaultLanguage };
