import { checkboxAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  label: {
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: '120%',
  },
  control: {
    _checked: {
      bg: 'brand.ink',
      borderColor: 'brand.ink',
      color: 'typography.lighter',
    },
  },
  icon: {},
});

const checkboxTheme = defineStyleConfig({
  variants: { base: baseStyle },
  defaultProps: { variant: 'base' },
});

export default checkboxTheme;
