import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const base = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'stroke.base',
    background: 'white',
    borderRadius: 'lg',
    p: 2,
    pl: 4,
    fontSize: '1rem',
    lineHeight: '150%',
    letterSpacing: '-0.02rem',
    _focus: {
      borderColor: 'brand.ink',
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'gray.200',
    background: 'gray.200',
    borderRadius: 'full',
    color: 'gray.500',
  },
});

const inputTheme = defineMultiStyleConfig({
  variants: { base },
  defaultProps: {
    variant: 'base',
  },
});

export default inputTheme;
