const getProductSchema = (shopifyProduct, activeSku) => {
  if (!shopifyProduct) {
    return null;
  }

  const refurbished = false;

  const selectedVariant =
    shopifyProduct.variants.find(variant => variant.sku === activeSku) ||
    shopifyProduct.variants[0];

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id': typeof window !== 'undefined' ? window.location.href : '',
    name: shopifyProduct.title,
    image: shopifyProduct?.media
      ?.map(({ image }) => image?.src)
      ?.filter(i => i)
      ?.at(0),
    description:
      shopifyProduct?.description || shopifyProduct.schemaDescription,
    sku: selectedVariant?.sku,
    gtin13: selectedVariant?.barcode,
    brand: {
      '@type': 'Brand',
      name: shopifyProduct?.vendor,
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: 'USD',
      price: selectedVariant?.price,
      itemCondition: refurbished
        ? 'https://schema.org/RefurbishedCondition'
        : 'https://schema.org/NewCondition',
      availability: selectedVariant?.availableForSale
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    },
  };
};

export default getProductSchema;
