import styles from '../components/utils/styles';

const colors = {
  neutral: {
    light: styles.colors.neutral[50],
    ...styles.colors.neutral,
  },
  primary: {
    light: styles.colors.primary[50],
    base: styles.colors.primary[400],
    dark: styles.colors.primary[600],
    extraDark: styles.colors.primary[800],
    ...styles.colors.primary,
  },
  secondary: {
    base: styles.colors.primary[700],
    hover: styles.colors.primary[900],
    ...styles.colors.secondary,
  },
  brand: {
    ink: styles.colors.primary[700],
    sunny: styles.colors.accent[500],
    ...styles.colors.blue,
  },
  sunny: {
    ...styles.colors.accent,
  },
  button: {
    ink: styles.colors.primary[800],
    sunny: styles.colors.accent[500],
    lightBlue: styles.colors.primary[100],
  },
  specialty: {
    golden: '#F7CD00',
    spring: '#A481D4',
    park: '#38C266',
    redClay: '#FC7F14',
    home: '#FA5955',
  },
  typography: {
    lighter: styles.colors.neutral[0],
    light: styles.colors.neutral[600],
    base: styles.colors.neutral[900],
  },
  stroke: {
    lighter: styles.colors.neutral[0],
    light: styles.colors.neutral[100],
    base: styles.colors.neutral[300],
    dark: styles.colors.primary[900],
  },
  background: styles.colors.neutral[0],
  alerts: {
    danger: {
      light: styles.colors.danger[100],
      base: styles.colors.danger[500],
      dark: styles.colors.danger[800],
    },
    warning: {
      light: styles.colors.warning[100],
      base: styles.colors.warning[500],
      dark: styles.colors.warning[800],
    },
    success: {
      light: styles.colors.success[100],
      base: styles.colors.success[500],
      dark: styles.colors.success[800],
    },
  },
  global: {
    neutral: styles.colors.neutral[0],
    neutral50: styles.colors.neutral[50],
    neutral100: styles.colors.neutral[100],
    neutral500: styles.colors.neutral[500],
  },
};

export default colors;
