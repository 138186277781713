import { defineStyleConfig } from '@chakra-ui/react';

const toastTheme = defineStyleConfig({
  variants: {
    success: ({ theme }) => ({
      container: {
        bg: theme.colors?.brand?.sunny,
        color: theme.colors?.typography?.base,
      },
    }),
  },
  defaultProps: {
    variant: 'success',
  },
});

export default toastTheme;
