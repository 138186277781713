import { defineStyleConfig } from '@chakra-ui/react';

// define the base component styles
const baseStyle = {
  maxW: '1680px',
  px: { base: '24px', md: '40px', lg: '80px', '2xl': '120px' },
};

// export the component theme
const containerTheme = defineStyleConfig({ baseStyle });
export default containerTheme;
