const getLanguageResolver = defaultLanguage => ({
  type: 'String',
  resolve(source) {
    return source.language ?? defaultLanguage;
  },
});

// This is a temporary helper function to filter out the language variants of the documents in Sanity
const getFilterByDefaultLanguageFunction =
  defaultLanguage =>
  (nodes = []) => {
    if (Array.isArray(nodes))
      return nodes.filter(
        node => !node.language || node.language === defaultLanguage
      );

    return [];
  };

module.exports = {
  getLanguageResolver,
  getFilterByDefaultLanguageFunction,
};
