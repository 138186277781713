import { defineStyleConfig } from '@chakra-ui/react';

const baseStyle = {
  alignItems: 'center',
  gap: '0.25rem',
  fontSize: 'xs',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  lineHeight: '120%',
  marginBottom: 1,
};

const formLabelTheme = defineStyleConfig({ baseStyle });

export default formLabelTheme;
