import breakpoints from '@rsc/ui-components/breakpoints';

const sizes = {
  container: {
    ...breakpoints,
  },
  productCardHeight: '37.6rem',
};

export default sizes;
