export const shrinkProductImage = (
  src: string | null,
  width: string | number
): string | null => {
  if (!src) return null;

  const url = new URL(src);
  url.searchParams.set('width', width.toString());
  return url.toString();
};
