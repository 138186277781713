import { extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

// Modules
import fonts from './fonts';
import images from './images';
import colors from './colors';
import sizes from './sizes';
import components from './components';
import typography from './typography';

export const baseTheme = {
  styles: {
    global: {
      'html, body': {
        color: 'typography.base',
      },
    },
  },
  fonts,
  images,
  colors,
  sizes,
  components,
  ...typography,
  cardBoxShadow: '0px 4px 56px 0px rgba(5, 37, 77, 0.15)',
};

export const proseConfig = {
  baseStyle: {
    h1: {
      ...typography.textStyles.h1,
      mb: { base: '16px !important' },
      mt: { base: '40px !important' },
    },
    h2: {
      ...typography.textStyles.h2,
      mb: { base: '16px !important' },
      mt: { base: '40px !important' },
    },
    h3: {
      ...typography.textStyles.h3,
      mb: { base: '16px !important' },
      mt: { base: '40px !important' },
    },
    p: {
      ...typography.textStyles.body,
      // overwrite body style margin
      marginBottom: '0.5rem !important',
    },
    table: {
      width: '100%',
      my: 0,
    },
    th: {
      fontWeight: '700 !important',
      p: '2.5 !important',
      whiteSpace: 'nowrap',
      border: '1px solid',
      borderColor: 'stroke.light',
    },
    td: {
      p: '2.5 !important',
      whiteSpace: 'nowrap',
      border: '1px solid',
      borderColor: 'stroke.light',
    },
    '#post-body, img': {
      borderRadius: '24px',
      my: '40px',
    },
    a: {
      color: 'primary.base',
      textDecoration: 'underline',
    },
  },
};

const customTheme = extendTheme(baseTheme, withProse(proseConfig));

export default customTheme;
