import { defineStyleConfig } from '@chakra-ui/react';

const baseStyle = {
  bg: 'brand.ink',
  color: 'typography.lighter',
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '120%',
  py: 1,
  px: 2,
  borderRadius: 'base',
  textTransform: 'inherit',
};

const badgeTheme = defineStyleConfig({ baseStyle });

export default badgeTheme;
