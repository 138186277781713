import { defineStyleConfig } from '@chakra-ui/react';

const base = {
  dialog: {
    maxW: {
      base: '100%',
      sm: '375px',
    },
  },
};

const drawerTheme = defineStyleConfig({
  variants: {
    base,
  },
  defaultProps: {
    variant: 'base',
  },
});

export default drawerTheme;
