import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const base = defineStyle({
  w: 'fit-content',
  fontWeight: 600,
  gap: '0.5rem',
  p: '0.75rem 1.5rem',
  borderRadius: '0.5rem',
  fontSize: '1rem',
  lineHeight: '150%',
  flexShrink: 0,
  color: 'typography.base',
  bg: 'brand.sunny',
  _hover: {
    bg: 'sunny.300 !important',
    textDecoration: 'none',
    _disabled: {
      bg: 'brand.sunny',
    },
  },
  transition: 'background-color 0.1s ease-in-out',
});

const primary = defineStyle({
  ...base,
  bg: 'primary.100',
  _hover: {
    bg: 'primary.200 !important',
    textDecoration: 'none',
    _disabled: {
      bg: 'primary.100',
    },
  },
});

const secondary = defineStyle({
  ...base,
  bg: 'secondary.base',
  color: 'typography.lighter',
  _hover: {
    bg: 'secondary.hover !important',
    textDecoration: 'none',
    _disabled: {
      bg: 'secondary.base',
    },
  },
});

const tertiary = defineStyle({
  ...base,
  bg: 'transparent',
  color: 'typography.base',
  border: '1px solid',
  borderColor: 'stroke.base',
  borderRadius: 'lg',
  _hover: {
    bg: 'brand.ink',
    color: 'typography.lighter',
    textDecoration: 'none',
    _disabled: {
      bg: 'transparent',
      color: 'typography.base',
    },
  },
  _disabled: {
    bg: 'transparent',
    color: 'typography.base',
    _hover: {
      bg: 'transparent',
      color: 'typography.base',
    },
  },
});

const nav = defineStyle({
  ...base,
  bg: 'transparent',
  color: 'typography.lighter',
  border: 'none',
  textDecor: 'none',
  _hover: {
    bg: 'primary.extraDark',
    textDecor: 'none',
    _disabled: {
      bg: 'transparent',
    },
  },
});

const link = defineStyle({
  bg: 'transparent',
  color: 'primary.base',
  _hover: {
    color: 'primary.dark',
    _disabled: {
      bg: 'transparent',
    },
  },
  textDecor: 'underline',
  border: 'none',
  fontSize: '0.875rem',
  fontWeight: 600,
});

const pagination = defineStyle({
  ...tertiary,
  borderColor: 'none',
  border: 'none',
  cursor: 'pointer',
  _hover: {
    bg: 'primary.100',
    _disabled: {
      bg: 'transparent',
    },
  },
});

const outOfStock = defineStyle({
  ...primary,
  borderColor: 'none',
  backgroundColor: 'neutral.100',
  cursor: 'not-allowed',
  color: 'typography.light',
  _hover: {
    backgroundColor: 'neutral.100',
    _disabled: {
      backgroundColor: 'neutral.100',
    },
  },
});

const outOfStockEmail = defineStyle({
  ...base,
  borderColor: 'none',
  border: 'none',
  outline: 'none',
  backgroundColor: 'none',
  px: '3',
  borderLeftRadius: 'none',
  borderRightRadius: '6',
});

const buttonTheme = defineStyleConfig({
  variants: {
    base,
    primary,
    secondary,
    tertiary,
    nav,
    link,
    pagination,
    outOfStock,
    outOfStockEmail,
  },
  defaultProps: {
    variant: 'base',
  },
});

export default buttonTheme;
