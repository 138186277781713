import PetSafeLogo from '../images/petsafe-logo.svg';
import PetSafeLogoV2 from '../images/petsafe-logo-v2.svg';
import PetSafeIsotypeV2 from '../images/petsafe-isotype.svg';
import footerSVG from '../images/footer.svg';
import footerSVGMobile from '../images/footer-mobile.svg';
import unleashJoyWhite from '../images/unleash-joy.svg';
import unleashJoyYellow from '../images/unleash-joy-yellow.svg';
import unleashJoyCompact from '../images/unleash-joy-compact.svg';
import greyBackground from '../images/half-scattered-images.svg';
import yellowLogo from '../images/yellow-logo.svg';
import sectionPattern from '../images/section-pattern.svg';
import circlesPattern from '../images/circle-pattern.svg';
import expertBanner from '../images/expert-banner.svg';
import ellipse from '../images/ellipse.svg';
import cloudBackground from '../images/cloud-background.svg';
import inkExpertBadge from '../images/ink-expert-badge.svg';

const images = {
  logo: PetSafeLogo,
  logoV2: PetSafeLogoV2,
  isotypeV2: PetSafeIsotypeV2,
  footer: footerSVG,
  footerMobile: footerSVGMobile,
  unleashJoyWhite,
  unleashJoyYellow,
  unleashJoyCompact,
  greyBackground,
  yellowLogo,
  sectionPattern,
  circlesPattern,
  expertBanner,
  ellipse,
  cloudBackground,
  inkExpertBadge,
};

export default images;
