export const filteredVariants = variants => {
  const acceptedLifecyle = ['Available', 'Coming Soon', 'Discontinued'];
  return variants.filter(v =>
    acceptedLifecyle.includes(v?.variantLifecycle?.value)
  );
};

export const addToCartBtnTxt = lifecycle => {
  switch (lifecycle) {
    case 'Coming Soon':
      return 'Coming Soon';
    case 'Obsolete':
      return 'Discontinued';
    default:
      return 'Out of Stock';
  }
};

export const variantOptionsFilter = variants => {
  if (variants.length === 1) return [];

  const modifiedOptions = variants
    .map(variant => variant?.selectedOptions)
    .flat(1)
    .reduce((res, { name, value }) => {
      let bin = res.find(e => e.name === name);

      if (!bin) {
        bin = { name, values: new Set() };
        res.push(bin);
      }
      bin.values.add(value);
      return res;
    }, []);

  modifiedOptions.forEach(e => {
    e.values = [...e.values];
  });

  return modifiedOptions;
};
